/* ANT SPINNER STYLES */

.ant-spin-dot-item {
  background-color: #FFD84E;
}

.ant-spin {
  color: #333;
}

/* spinner on tables, when loading = true */
.ant-table-wrapper .ant-spin-dot-item {
  background-color: #555555;
}
