.ant-page-header-heading-title {
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  color: #000000;
}

.ant-page-header {
  padding-left: 0px;
}

.ant-page-header-heading-sub-title {
  padding-top: 4px;
}
