.flex-filters-btn {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

/* DATE PICKER */
.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
  border: 1px solid #FFD84E;
}

.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
  background: rgba(254, 211, 83, 0.377);
}

.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
  background: rgba(254, 211, 83, 0.377);
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
  background: #FFD84E;
  color: #333;
  font-weight: 600;
}

.ant-picker-ranges .ant-picker-preset>.ant-tag-blue {
  color: #333;
  background: #FFD84E;
  border-color: #FFD84E;
  cursor: pointer;
  border-radius: 4px;
  font-weight: 600;
}

/* .ant-picker:hover, .ant-picker-focused {
  border-color: #FFD84E;
} */

/* .ant-picker-focused {
  border-color: rgb(255, 216, 78);
  box-shadow: 0 0 0 2px rgb(255, 216, 78);
} */

/* .ant-picker-range .ant-picker-active-bar {
  background: #cea100;
} */

/* .ant-picker-cell .ant-picker-cell-in-view .ant-picker-cell-range-hover-start {
  border-color: red
} */

/* FILTER BY INSTRUCTOR */
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 7px;
}

/* .ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: #FFD84E;
  border-right-width: 1px;
} */

/* .ant-select-focused:not(.ant-select-disabled) .ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: #FFD84E;
} */

.select-field {
  width: 210px;
  margin-right: 15px;
}

/* FILTER BY TIME RANGE */
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
  color: #333;
  background: #FFD84E;
  border-color: #FFD84E;
}

.ant-radio-button-wrapper:first-child {
  border-top-left-radius: 7px;
  border-bottom-left-radius: 7px;
}

.ant-radio-button-wrapper:last-child {
  border-top-right-radius: 7px;
  border-bottom-right-radius: 7px;
}

.ant-radio-button-wrapper:hover {
  color: black;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before {
  background-color: #FFD84E;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before:hover {
  background-color: #FFD84E;
}

/* FILTER VIEWS ONLY */
.ant-switch-checked {
  background: #FFD84E;
}

/* SEARCHING */
.input-search {
  width: 350px;
  border-radius: 7px;
}

/* CREATE NEW INSTRUCTOR - DRAWER */
.ant-drawer-title {
  font-weight: 600;
}

/* CREATE NEW INSTRUCTOR - FORM */
.btn-in-drawer {
  width: -webkit-fill-available;
}

.new-instructor-form .ant-col .ant-form-item-label {
  padding: 0 0 1px;
}

/* CREATE NEW INSTRUCTOR - SUCCESSFUL CREATE */
.section-with-results {
  border: 1px dashed #444444;
  border-radius: 6px;
}

.fields-for-copy {
  display: flex;
  align-items: baseline;
  font-size: 20px;
  margin: 15px 0px 15px 15px;
  width: 418px;
  height: 35px;
}

.for-copy-email, .for-copy-password {
  margin-right: 10px;
}

.btn-result-retry-creation {
  margin-top: 15%;
  max-width: 455px !important;
  width: -webkit-fill-available;
}
