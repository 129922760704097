@import '~antd/dist/antd.css';
@import url('./fonts/univia-pro/stylesheet.css');
@import url('./fonts/SFPro/stylesheet.css');
@import url('./styles/style.css');
@import url('./styles/style-header.css');
@import url('./styles/style-filters.css');
@import url('./styles/style-buttons.css');
@import url('./styles/style-tables.css');
@import url('./styles/style-instructor-card.css');
@import url('./styles/style-marathon-card.css');
@import url('./styles/style-marketing.css');
@import url('./styles/style-insights.css');
@import url('./styles/style-admin-page.css');
@import url('./styles/style-preview.css');
@import url('./styles/style-seasons.css');
@import url('./styles/style-external-events.css');
@import url('./styles/style-users.css');

body {
  font-family: 'Open Sans', sans-serif;
}

/* DRAGGABLE ROW IN TABLE */
.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
  display: flex;
  align-items: center;
}

.row-dragging td {
  padding: 0px 20px 0px 20px;
}

.row-dragging .drag-visible {
  visibility: visible;
}

/* TOOLTIPS STYLES */
.ant-tooltip-inner {
  white-space: pre-line;
  color: #333333;
  background-color: #ffffff;
}

.ant-tooltip-arrow-content {
  --antd-arrow-background-color: linear-gradient(to right bottom, #ffffff, rgb(242 242 242));
}

.ant-menu-inline-collapsed-tooltip a, .ant-menu-inline-collapsed-tooltip a:hover {
  color: #333333;
}

/* INPUT STYLES */
.ant-input {
  border-radius: 6px;
}

/* DATE PICKER STYLES */
.ant-picker {
  border-radius: 6px;
}

/* UPLOAD PHOTO STYLES */
.ant-upload.ant-upload-select-picture-card {
  border-radius: 6px;
}

/* SELECT BASIC STYLES */
.ant-segmented {
  border-radius: 6px;
}

.ant-segmented-item-selected {
  border-radius: 6px;
}

.ant-segmented-item-selected {
  background-color: #FFD84E;
}

/* UPLOAD IMAGE STYLES */
.ant-upload.ant-upload-select-picture-card {
  margin-right: 0;
  margin-bottom: 0;
}

.ant-upload-list-picture-card-container {
  margin: 0;
}

/* UNKNOWN ANT STYLES */
.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):hover {
  color: #333;
  background: #FFD84E;
  border-color: #FFD84E;
}

.table-row-light {
    background-color: #ffffff;
}
.table-row-dark {
    background-color: #fbfbfb;
}

.ant-btn-primary:focus {
  background-color: rgb(254,	211, 83);
  color: rgb(64, 68, 82);
  border-color: rgb(254,	211, 83);
}

.ant-input-affix-wrapper:not(.custom-input-affix-wrapper-disabled):hover {
  border-color: #1890ff;
  border-right-width: 1px;
  z-index: 1;
}

.ant-input-affix-wrapper-focused {
  border-color: #0b81f0;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    z-index: 1;
    color: #444;
    background: #fff;
    border-color: #1890ff;
}

html {
  --antd-wave-shadow-color: brown;
}


.ant-descriptions-header {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.ant-descriptions-row > th {
  width: 30%;
}



#create-or-edit-trainings .ant-descriptions-row > th {
  width: 15%;
}