/* FONT STYLE IN TABLE HEADERS */
.ant-table-column-title,
.ant-table-thead>tr>th {
  font-weight: 400;
  font-size: 13px;
  color: #666666;
}

/* INSTRUCTORS TABLE */
.ant-table-tbody > tr > td.instructors-table {
  padding-top: 7px;
  padding-bottom: 7px;
}

/* TRAINING COLUMN */
.training-section {
  padding-bottom: 8px;
}

.column-name {
  font-weight: 700;
  font-size: 16px;
  line-height: 27px;
  color: #444444;
}

.column-training-name {
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  color: #555555;
}

/* DATE COLUMN */
.date-flex {
  display: flex;
  justify-content: space-around;
  align-items: baseline;
  margin-bottom: -12px;
}

.title-start-day-month-time-training {
  text-align: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 15px;
  color: #555555;
}

.start-time-training {
  font-weight: 600;
  font-size: 18px;
  line-height: 26px;
  color: #333333;
  margin-top: 8%;
}

.title-start-training {
  text-align: center;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  color: #555555;
}

.start-time-fact-training-is-late {
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  color: #BF616A;
  margin-top: 6%;
}

.start-time-fact-training-no-late {
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  color: #A3BE8C;
  margin-top: 6%;
}

.difference-plan-actual-is-late {
  text-align: center;
  font-weight: 500;
  font-size: 10px;
  line-height: 11px;
  color: #BF616A;
}

.difference-plan-actual-no-late {
  text-align: center;
  font-weight: 500;
  font-size: 10px;
  line-height: 11px;
  color: #A3BE8C;
}

.title-end-training {
  text-align: center;
  font-weight: 400;
  font-size: 10px;
  line-height: 15px;
  color: #555555;
}

.end-training {
  font-weight: 500;
  font-size: 18px;
  line-height: 25px;
  color: #888888;
  margin-top: 6%;
}

/* STATUS COLUMN */
.column-status {
  text-align: center;
  display: flex;
  justify-content: center;
  margin-left: 8px;
}

/* VISITORS COLUMN */
.visitors-flex {
  display: flex;
  justify-content: space-around;
}

.title-amount-of-users {
  text-align: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #555555;
}

.amount-of-users {
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #333333;
}

.title-involved-users {
  text-align: center;
  font-weight: 400;
  font-size: 12px;
  line-height: 15px;
  color: #555555;
}

.involved-users {
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  color: #333333;
}

/* RECORD COLUMN */
.icon-player {
  font-size: 32px;
  color: #5c5c5c;
  position: absolute;
  left: 50%;
  transform: translate(-50%, -50%);
}

/* TIME COLUMN - TRAININGS RECORDS TABLE */
.time-wrapper {
  text-align: center;
}

.training-time {
  font-size: 18px;
  font-weight: 600;
  /*padding-top: 6px;*/
  /*position: absolute;
  top: 24%;
  left: 50%;
  transform: translate(-50%, -50%);*/
}


.training-date {
  font-size: 10px;
  color: #555;
  /*padding-top: 20px;*/
}

.training-date pre {
  margin: 0;
}

/* CONTACTS COLUMN - INSTRUCTORS TABLE */
.instructor-phone {
  font-weight: 500;
  font-size: 17px;
  color: #222222;
}

.instructor-email {
  font-weight: 500;
  font-size: 14px;
  color: #222222;
}

/* INSIGHTS TABLE */

.insights-title-column {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  color: #404040;
}

.insights-table-cell-container {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: #404040;
}

.ellipsis-cell {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  word-break: keep-all;
}

.insights-title-text {
  margin-bottom: 3px;
}

.insights-rate-section {
  display: flex;
  align-items: center;
}

.insights-rating-stars {
  font-size: 15px;
}

.insights-rate-volume {
  margin-right: 5px;
  margin-left: 10px;
}

.insights-votes-count {
  font-weight: 300;
}

.insights-icon img {
  border-radius: 12px;
  width: 56px;
  height: 73px;
  object-fit: cover;
  cursor: pointer;
}

/* EXTERNAL EVENTS TABLE - start */
  .ext-event-name {
    font-size: 16px !important;
  }

  .table-cell-container.ext-event-seasons-column {
    display: flex;
    justify-content: space-evenly;
    line-height: 11px;
    align-items: center;
  }

  .ext-event-date-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .ext-event-date-time {
    font-size: 11px;
    color: #808080
  }
/* end - EXTERNAL EVENTS TABLE */


