.segmented-custom-wrapper,
.segmented-custom-wrapper.ant-segmented:not(.ant-segmented-disabled):hover, .ant-segmented:not(.ant-segmented-disabled):focus {
  background-color: white;
}

.segmented-custom-wrapper .ant-segmented-thumb.ant-segmented-thumb-motion-appear.ant-segmented-thumb-motion-appear-active.ant-segmented-thumb-motion {
  background-color: #FFD84E;
  border-radius: 6px;
}

.spinner {
  position: absolute;
  left: 57%;
  top: 50%;
}

.spinner .ant-spin-dot-item {
  background-color: #555555;
}

.card-wrapper {
  background: #FFFFFF;
  border-radius: 6px;
  padding: 20px 20px 20px 20px;
  display: grid;
  grid-column-gap: 10px;
  grid-row-gap: 1px;
  grid-template-columns: 200px 6fr 2fr;
  grid-template-areas:
    "img marathon-type status"
    "img training-name ."
    "img instructor-name ."
    "img tags ."
    "img . ."
    "img training-volume ."
    "img duration members"
    "divider divider divider"
    "btn-edit . btn-clone";
  margin-bottom: 15px;
}

.dynamic-fields-wrapper {
  display: grid;
  grid-template-columns: 22px 1fr 15px;
  grid-column-gap: 10px;
  grid-template-areas: 
    ". text-area ."
    "icon-menu text-area icon-minus"
    ". text-area .";
}

.marathons-results-wrapper,
.marathons-included-wrapper {
  background-color: #F2F2F2;
  padding: 20px 20px 10px 20px;
  border-radius: 16px;
}

.form-list-add-button {
  display: grid;
  grid-template-columns: 22px 1fr 15px;
  grid-column-gap: 10px;
  grid-template-areas: ". add-btn .";
}

.add-btn {
  grid-area: add-btn;
}

.upload-images-label {
  display: flex;
  align-items: center;
}

.icon-menu-outlined {
  grid-area: icon-menu;
  align-self: center;
  line-height: 11px;
  cursor: grab;
  color: #404040;
}
.text-area {
  grid-area: text-area;
  margin-bottom: 0;
}
.icon-minus {
  grid-area: icon-minus;
  align-self: center;
}

.card-marathons-tags {
  grid-area: tags;
}
.card-cover-container {
  grid-area: img;
  width: 200px;
  height: 200px;
}

.card-cover-container>.ant-image-img {
  border-radius: 6px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.card-marathon-type {
  grid-area: marathon-type;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  color: #444444;
  line-break: normal;
  display: block;
  text-align: left;
}

.card-training-name {
  grid-area: training-name;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  color: #444444;
}

.card-status {
  grid-area: status;
  justify-self: end;
  align-self: center;
}

.card-status>.custom-tag {
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
  padding: 3px 8px 3px 8px;
  border-radius: 6px;
  margin-right: 0;
}

.card-marathons-tags>.is-recommended-tag {
  line-height: 18px;
  font-weight: 600;
  color: #ffffff;
  border-radius: 12px;
}

.card-marathons-tags>.tags {
  line-height: 18px;
  border-radius: 12px;
}

.card-instructor-name {
  grid-area: instructor-name;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  color: #888;
}

.card-training-volume {
  grid-area: training-volume;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #333333;
  display: flex;
  align-items: center;
}

.card-training-volume>span {
  margin-left: 5px;
  margin-right: 5px;
}

.card-training-volume>.how-many-trainings-planed,
.card-training-volume>.how-many-trainings-completed {
  color: #888888;
  font-weight: 400;
}

.card-duration {
  grid-area: duration;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  color: #333333;
  display: flex;
  align-items: center;
}

.card-duration>span {
  margin-left: 5px;
  margin-right: 5px;
}

.card-duration>.how-many-days-left {
  color: #888888;
  font-weight: 400;
}

.card-members {
  grid-area: members;
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #333333;
  justify-self: end;
  align-self: center;
  display: flex;
  align-items: center;
}

.card-members>span {
  margin-right: 5px;
}

.card-divider {
  grid-area: divider;
}

.card-divider.ant-divider-horizontal {
  margin: 15px 0;
}

.card-btn-edit {
  grid-area: btn-edit;
  background: #FFFFFF;
  border: 1px solid rgba(136, 136, 136, 0.46);
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 200px;
}

.card-btn-edit>span {
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #333333;
  margin-left: 5px;
}

.card-btn-edit>svg {
  margin-left: -5px;
}

.card-btn-clone {
  grid-area: btn-clone;
  justify-self: end;
  background: #FFFFFF;
  border: 1px solid rgba(136, 136, 136, 0.46);
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 200px;
}

.card-btn-clone>span {
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #333333;
  margin-left: 5px;
}

.card-btn-clone>svg {
  margin-left: -5px;
}

.current-marathon-name {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #555555;
}

.info-text {
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  color: #000000;
}

.images {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* FOR ANTD CALENDAR */

.events {
  margin: 0;
  padding: 0;
  list-style: none;
}
.events .ant-badge-status {
  width: 100%;
  overflow: hidden;
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.notes-month {
  font-size: 28px;
  text-align: center;
}
.notes-month section {
  font-size: 28px;
}

.custom-calendar-cell {
  height: -webkit-fill-available;
}

.calendar-wrapper .ant-picker-cell-in-view {
  background: #fab61440;
}

.calendar-wrapper .ant-picker-cell {
  background: #fab61440;
  border: 1px solid aliceblue;
}

.calendar-wrapper .ant-picker-cell-disabled {
  background: none;
}

/* MODAL */

.training-wrapper {
  display: grid;
  grid-column-gap: 10px;
  grid-row-gap: 1px;
  grid-template-columns: 1fr 5fr 1fr;
  grid-template-areas:
    "date training-name trash"
    "date instructor trash";
  grid-template-rows: 27px 27px;
  /* border: dashed 1px; */
  border-radius: 20px;
  padding: 0px 10px 0px 10px;
  margin-bottom: 10px;
}

.training-to-remove {
  text-decoration: line-through;
}

.training-start-date {
  grid-area: date;
  font-weight: 700;
  font-size: 36px;
  color: #222222;
  align-self: center;
}

.training-name {
  grid-area: training-name;
  font-weight: 600;
  font-size: 20px;
  color: #222222;
}

.training-instructor {
  grid-area: instructor;
  font-weight: 600;
  font-size: 12px;
  color: #555555;
}

.trash {
  grid-area: trash;
  align-self: center;
  justify-self: end;
  cursor: pointer;
}

.submit-btn-row {
  display: flex;
  justify-content: flex-end;
}

.submit-btn-row-wrapper {
  margin-bottom: 0;
}

.modal-cancel-btn {
  border-radius: 6px;
}

.modal-cancel-btn:hover {
  border-color: rgb(254,	211, 83);
  color: rgb(254,	211, 83);
  box-shadow: 0px 0px 6px 2px rgba(176, 176, 175, 0.3);
}

.modal-content-wrapper {
  margin-bottom: 10px;
}

/* TIPS */
.tips-card {
  display: grid;
  grid-template-columns: 15px 1fr 15px;
  grid-column-gap: 10px;
  grid-template-areas: 
    ". content ."
    "move-icon content delete-icon"
    ". content .";
}

.move-icon {
  grid-area: move-icon;
  align-self: center;
  line-height: 11px;
  cursor: grab;
  color: #404040;
}

.content-fields {
  grid-area: content;
  background-color: #F2F2F2;
  padding: 15px 20px 5px 20px;
  border-radius: 16px;
}

.content-fields>:nth-child(1) {
  margin-bottom: 10px;
}

.delete-icon {
  grid-area: delete-icon;
  align-self: center;
}

.tips-list .ant-draggable-form-list-item {
  border: 2px dashed #00000000;
  margin-bottom: 25px;
  border-radius: 16px;
}

.tips-list .ant-draggable-form-list-item.drop-over-downward {
  border-color: #1890ff;
}

.tips-list .ant-draggable-form-list-item.drop-over-upward {
  border-color: #1890ff;
}

.tips-spinner {
  position: absolute;
  left: 50%;
  top: 50%;
}

.tips-spinner .ant-spin-dot-item {
  background-color: #555555;
}

/* TRAININGS PLAN */
.input-readonly-part {
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
  color: #222222 !important;
  cursor: default !important;
}

.input-readonly-start-time {
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  color: #555555 !important;
  cursor: default !important;
}

.text-area-part-description {
  font-weight: 700;
  font-size: 14px;
  color: #3E3E3E;
}

.hidden-element {
  display: none;
}

.trainings-plan-list-wrapper .ant-draggable-form-list-item {
  border: 2px dashed #00000000;
  margin-bottom: 10px;
  border-radius: 10px;
}

.trainings-plan-list-wrapper .ant-draggable-form-list-item-is-part{
  border: 2px dashed #00000000;
  margin-bottom: 10px;
  margin-top: 30px;
  border-radius: 16px;
}

.trainings-plan-list-wrapper .ant-draggable-form-list-item.drop-over-downward {
  border-color: #1890ff;
}

.trainings-plan-list-wrapper .ant-draggable-form-list-item.drop-over-upward {
  border-color: #1890ff;
}

.trainings-plan-list-wrapper .ant-draggable-form-list-item-is-part.drop-over-downward {
  border-color: #1890ff;
}

.trainings-plan-list-wrapper .ant-draggable-form-list-item-is-part.drop-over-upward {
  border-color: #1890ff;
}

/* TABS */
.tab-wrapper {
  display: flex;
  align-items: center;
  margin: 0 5px 0 2px;
}

.marathon-tabs-wrapper .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #1967D2;
  text-shadow: 0 0 0.25px currentcolor;
}

.marathon-tabs-wrapper .ant-tabs-ink-bar {
  position: absolute;
  background: #1967D2;
  pointer-events: none;
  height: 3px !important;
}

.marathon-tabs-wrapper .ant-tabs-tab:hover {
  color: #1967D2;
}

/* CERTIFICATES */
.certificate {
  background-color: #F2F2F2;
  padding: 20px 25px 0px 25px;
  border-radius: 16px;
  margin-bottom: 20px;
}
