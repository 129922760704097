/* INSIGHTS PAGE */
.header-buttons {
  display: grid;
  grid-template-columns: 2fr 1fr;
  column-gap: 20px;
}

/* INSIGHTS FORM */
.categories-button {
  padding-left: 31px;
  padding-right: 31px;
}

  /* upload button */
.upload-thumbnail .ant-upload.ant-upload-select-picture-card {
  width: 100px;
  height: 100px;
}

.upload-story .ant-upload.ant-upload-select-picture-card {
  width: 100px;
  height: 200px;
  margin-top: 21px;
}
  /* uploaded image */
.upload-thumbnail .ant-upload-list-picture-card-container {
  width: 100px;
  height: 100px;
}

.upload-story .ant-upload-list-picture-card-container {
  width: 100px;
  height: 220px;
}

.upload-story .ant-upload-list-picture-card .ant-upload-list-item {
  height: 90%;
  margin: 0;
}

  /* upload stories */
.ant-upload-draggable-list-item {
  width: inherit;
  height: inherit;
  border-top: 2px dashed #00000000;
  border-bottom: 2px dashed #00000000;
  border-right: 2px dashed #00000000;
  border-left: 2px dashed #00000000;
}
.ant-upload-draggable-list-item.drop-over-leftward {
  border-top-color: #1890ff;
  border-bottom-color: #1890ff;
  border-left-color: #1890ff;
  border-right-color: #1890ff;
}
.ant-upload-draggable-list-item.drop-over-rightward {
  border-top-color: #1890ff;
  border-bottom-color: #1890ff;
  border-left-color: #1890ff;
  border-right-color: #1890ff;
}

/* CATEGORIES FORM */
.insights-categories-card {
  background-color: #F2F2F2;
  padding: 25px 30px 10px 30px;
  margin-bottom: 20px;
  border-radius: 16px;
}
