/* SEASON CARD - start */
.season-card-wrapper {
  background: #FFFFFF;
  border-radius: 6px;
  padding: 20px 20px 20px 20px;
  display: grid;
  grid-column-gap: 10px;
  grid-row-gap: 1px;
  grid-template-columns: 200px 6fr 2fr;
  grid-template-areas:
    "img training-name status"
    "img instructor-name ."
    "img . ."
    "img marathons-volume ."
    "img training-volume ."
    "img events-volume ."
    "img duration ."
    "divider divider divider"
    "btn-edit . btn-chat";
  margin-bottom: 15px;
}

.season-card-cover-container {
  grid-area: img;
  width: 200px;
  height: 200px;
}

.season-card-cover-container>.ant-image-img {
  border-radius: 6px;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.season-card-training-name {
  grid-area: training-name;
  font-weight: 700;
  font-size: 24px;
  line-height: 33px;
  color: #444444;
}

.season-card-status {
  grid-area: status;
  justify-self: end;
  align-self: center;
}

.season-card-status>.season-custom-tag {
  font-weight: 300;
  font-size: 16px;
  line-height: 22px;
  color: #000000;
  padding: 3px 8px 3px 8px;
  border-radius: 6px;
  margin-right: 0;
}

.season-card-instructor-name {
  grid-area: instructor-name;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  color: #888;
}

.season-card-training-volume {
  grid-area: training-volume;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #333333;
  display: flex;
  align-items: center;
}

.season-card-training-volume>span {
  margin-left: 5px;
  margin-right: 5px;
}

.season-card-training-volume>.season-how-many-trainings-planed,
.season-card-training-volume>.season-how-many-trainings-completed {
  color: #888888;
  font-weight: 400;
}

.season-card-marathons-volume {
  grid-area: marathons-volume;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #333333;
  display: flex;
  align-items: center;
}

.season-card-marathons-volume>span {
  margin-left: 5px;
  margin-right: 5px;
}

.season-card-marathons-volume>.season-how-many-marathons-completed {
  color: #888888;
  font-weight: 400;
}

.season-card-events-volume {
  grid-area: events-volume;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #333333;
  display: flex;
  align-items: center;
}

.season-card-events-volume>span {
  margin-left: 5px;
  margin-right: 5px;
}

.season-card-events-volume>.season-how-many-events-canceled,
.season-card-events-volume>.season-how-many-events-completed {
  color: #888888;
  font-weight: 400;
}

.season-card-duration {
  grid-area: duration;
  font-weight: 600;
  font-size: 18px;
  line-height: 20px;
  color: #333333;
  display: flex;
  align-items: center;
}

.season-card-duration>span {
  margin-left: 5px;
  margin-right: 5px;
}

.season-card-duration>.season-how-many-days-left {
  color: #888888;
  font-weight: 400;
}

.season-card-divider {
  grid-area: divider;
}

.season-card-divider.ant-divider-horizontal {
  margin: 15px 0;
}

.season-card-btn-edit {
  grid-area: btn-edit;
  background: #FFFFFF;
  border: 1px solid rgba(136, 136, 136, 0.46);
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 200px;
}

.season-card-btn-edit>span {
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #333333;
  margin-left: 5px;
}

.season-card-btn-edit>svg {
  margin-left: -5px;
}

.season-card-btn-chat {
  grid-area: btn-chat;
  display: flex;
  justify-content: center;
  align-items: center;
  justify-self: end;
  padding-left: 0;
  padding-right: 0;
}

.season-card-btn-chat>a {
  text-decoration-line: underline;
  font-weight: 600;
  font-size: 14px;
  line-height: 22px;
  color: #333333;
  margin-right: 5px;
}
/* SEASON CARD - end */

/* SEASON FORM - start */
.season-form-header-name {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #555555;
}

.season-marathon-selector-item-wrapper,
.ext-event-marathon-selector-item-wrapper {
  display: grid;
  grid-template-columns: 50px 1fr 1fr;
  column-gap: 10px;
}

.season-marathon-selector-img-container,
.ext-event-marathon-selector-img-container {
  width: 50px;
  height: 50px;
}

.season-marathon-selector-img,
.ext-event-marathon-selector-img {
  width: 50px;
  height: 50px;
  object-fit: cover;
}

.season-marathon-selector-name,
.ext-event-marathon-selector-name {
  align-self: center;
}

.season-marathon-selector-date,
.ext-event-marathon-selector-date {
  color: #888888;
  align-self: center;
}

.ext-event-marathon-selector-date {
  display: grid;
  grid-row-gap: 3px;

}

.ext-event-marathon-selector-date > .start-date {
  margin-left: 4px;
  font-weight: 600;
  line-height: 14px;
}

.ext-event-marathon-selector-date > .time {
  font-size: 11px;
  line-height: 10px;
}

.ant-select-item-option-state {
  align-self: center;
}
/* SEASON FORM - end */
