.ant-btn-primary {
  border-radius: 6px;
  background-color: rgb(254,	211, 83, 0.9);
  color: rgb(64, 68, 82);
  border-color: rgb(254,	211, 83);
  /* margin-top: 20px; */
}
.ant-btn-primary:hover {
  background-color: rgb(254,	211, 83);
  color: rgb(64, 68, 82);
  border-color: rgb(254,	211, 83);
  box-shadow: 0px 0px 6px 2px rgba(176, 176, 175, 0.3);
}

.ant-btn.ant-btn-default {
  /* border-radius: 6px; */
  /* border-color: rgb(254,	211, 83); */
  /* color: rgb(64, 68, 82); */
  /* background-color: rgb(254,	211, 83); */
  /* box-shadow: 0px 0px 6px 2px rgba(176, 176, 175, 0.3); */
  /* max-width: 200px; */
}

.btn-bottom {
  top: 30px;
}
