.ant-card.instructor-card {
  border-radius: 6px;
  min-width: 320px;
  height: 600px;
  margin-bottom: 6px;
}

.ant-card.instructor-card-second {
  border-radius: 6px;
  /*width: 320px;*/
  /*height: 600px;*/
}

.avatar-status-rating {
  align-items: center;
  display: grid;
  grid-template-columns: 100px 1fr;
  grid-gap: 10px;
  /* justify-items: center; */
}

.btn-edit-card {
  width: -webkit-fill-available;
  top: 50px;
}

.level-wrapper {
  /* margin-right: 15px; */
  display: flex;
  justify-content: space-between;
}

.level-wrapper>.status-tag {
  margin: 0;
}

.rating-wrapper {
  margin-top: 7px;
  display: flex;
  justify-content: space-between;
}

.rating-value {
  background-color: #e9e9e9;
  border-radius: 50px;
  padding: 4px;
  font-weight: 700;
  font-size: 10px;
  color: #444444;
}

.user-description {
  display: flex;
  flex-direction: column;
  margin-top: 22px;
}

p.main-info {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 2px;
  margin-bottom: 0;
  color: #333333;
}

.about-facts-box {
  margin-top: 22px;
}

p.about-facts {
  width: 100px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 2px;
  margin-bottom: 0;
  color: #000000;
}

.main-info>span {
  margin-left: 7px;
}

.master-trainings-wrapper {
  border-radius: 6px;
  background: #E3E8EE;
  margin-top: 40px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding-bottom: 5px;
  padding-top: 5px;
}

.master-trainings-wrapper-skeleton {
  border-radius: 6px;
  background: #E3E8EE;
  margin-top: 40px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}

.master-trainings-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.master-trainings-title div:nth-child(1) {
  margin-left: 15px;
  font-weight: 600;
  font-size: 15px;
  color: #555555;
}

.master-trainings-info {
  display: flex;
  align-items: center;
}

.master-trainings-info div:nth-child(2) {
  margin-left: 15px;
}

.amount-of-trainings {
  font-weight: 700;
  font-size: 28px;
  line-height: 38px;
  color: #333333;
}

.instructor-id {
  position: absolute;
  top: 94%;
  left: 19%;
  font-weight: 400;
  font-size: 11px;
  color: #666666;
}

.btn-result-successful {
  max-width: 300px !important;
  width: -webkit-fill-available;
  margin-left: 27%;
}

.result-successful {
  margin-top: 20%;
}

/* MASTER-TRAINING CARD - start */
  .card-master-training {
    background: rgba(196, 196, 196, 0.2);
    padding: 15px 15px 15px 15px;
    display: grid;
    grid-column-gap: 10px;
    grid-template-columns: 150px 1fr 32px;
    grid-template-areas:
      "img training-name btn"
      "img . ."
      "img duration ."
      "img calories .";
    border-radius: 6px;
    margin-bottom: 20px;
  }

  .picture-box-master-training {
    grid-area: img;
    width: 150px;
    height: 150px;
  }

  .picture-box-master-training>.ant-image-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 6px;
  }

  .title-master-training {
    grid-area: training-name;
    font-weight: 600;
    font-size: 18px;
    color: #000000;
    line-height: 18px;
  }

  .btn-edit-card-master-training {
    grid-area: btn;
  }

  .length-master-training {
    grid-area: duration;
    font-weight: 400;
    font-size: 14px;
    color: #000000;
  }

  .ccal-master-training {
    grid-area: calories;
    font-weight: 400;
    font-size: 14px;
    color: #000000;
    line-height: 16px;
  }
/* MASTER-TRAINING CARD - end */

.edit-master-training-form {
  margin: 10px 40px 0px 40px;
}

.btn-back-to-list {
  bottom: 20px;
  right: 35px;
  color: rgba(0, 0, 0, 0.85);
  font-weight: 700;
  font-size: 16px;
}
