/* FORM - start */
  /* .external-events-date-wrapper {
    display: flex;
    align-items: center;
    border-radius: 7px;
    border: 1px solid #d9d9d9;
    margin-top: 1px;
    height: 32px;
  }

  .external-events-date-range-picker-separator {
    color: #d9d9d9;
    font-size: 18px;
  } */

  .external-events-season-selector-item-wrapper {
    display: grid;
    grid-template-columns: 50px 1fr 1fr;
    column-gap: 10px;
  }
  
  .external-events-season-selector-img-container {
    width: 50px;
    height: 50px;
  }
  
  .external-events-season-selector-img {
    width: 50px;
    height: 50px;
    object-fit: cover;
  }
  
  .external-events-season-selector-name {
    align-self: center;
  }
  
  .external-events-season-selector-date {
    color: #888888;
    align-self: center;
  }
/* FORM - end */
