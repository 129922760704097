.table-cell-container {
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 18px;
  color: #000000;
}

.ant-typography.promocodes-cell {
  margin-bottom: 0;
}

.promocodes-cell {
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 10px;
}

.promocodes-cell>div:nth-child(1) {
  margin-right: 62px;
}

.promotions-table-groups-column,
.deals-table-goods-column,
.price-tags-table-marathons-column {
  display: flex;
  justify-content: space-evenly;
  line-height: 10px;
  align-items: center;
}

.actions-column>button:nth-child(3) {
  padding-right: 0px;
  padding-left: 10px;
}

.disabled-action-button>button {
  padding-right: 0px;
  padding-left: 10px;
}

.table-cell-container>.ant-tag {
  margin-right: 0;
}

.ant-draggable-form-list-item {
  border: 2px dashed #00000000;
  margin-bottom: 25px;
  border-radius: 6px;
}
.ant-draggable-form-list-item.drop-over-downward {
  border-color: #1890ff;
}
.ant-draggable-form-list-item.drop-over-upward {
  border-color: #1890ff;
}

.form-list-item-wrapper {
  display: grid;
  align-items: center;
  grid-template-columns: 15px 1fr 3fr 15px;
  grid-column-gap: 10px;
}

.icon-label {
  display: flex;
  align-items: center;
}
