.preview-wrapper {
  background-color: #d9d9d9c7;
  position: absolute;
  width: 280px;
  height: 400px;
  left: -280px;
  top: 0px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(3px);
}

/* .preview-wrapper figure {
  background-image: url(./images/iphone.png);
  border-radius: 54px;
  background-repeat: no-repeat;
  background-size: contain;
  width: 344px;
  height: 704px;
  box-shadow:
    0 2px 8px rgba(2,8,20,.1),
    0 8px 16px rgba(2,8,20,.08);
  margin: 0;
  overflow: hidden;
  position: relative;
  z-index: 1;
  padding: 38px 15px 24px 15px;
} */

/* .preview-wrapper .docs-demo-device__ios-notch,
.preview-wrapper figure:after {
  display: block;
} */

/* .docs-demo-device__ios-notch {
  fill: #090a0d;
  top: 12px;
  width: 165px;
  z-index: 2;
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
} */

/* .preview-wrapper figure:after {
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  background-color: rgba(0,0,0,.5);
  border-radius: 2px;
  bottom: 18px;
  content: "";
  height: 4px;
  width: 35%;
  z-index: 1;
} */

.preview-content-wrapper {
  
}

.price-tag-wrapper {
  display: grid;
  grid-template-rows: 32px 42px 1fr 89px;
  grid-template-columns: 162px;
  align-items: center;
  justify-content: center;
  padding: 17px 13px 7px 13px;
  row-gap: 3px;
  width: 189px;
  height: 300px;
  background: #F4F3F7;
  border-radius: 12px;
}

.space {
  row-gap: 1px;
}

.price-tag-wrapper .title-section {
  display: flex;
  align-items: center;
}

.price-tag-wrapper .title-section span {
  font-family: 'SF Pro Display';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: normal;
  min-width: 132px;
  max-width: 132px;
}

.smaller-font {
  font-size: 12px !important;
}

.price-tag-wrapper .title-section img {
  width: 35px;
}

.price-tag-wrapper .price-section .price {
  font-family: 'Univia Pro';
  font-style: normal;
  font-weight: 800;
  font-size: 24px;
  line-height: 24px;
  color: #110628;
}

.price-tag-wrapper .price-section .subtitle {
  font-family: 'SF Pro Display';
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 12px;
  letter-spacing: 0.38px;
  color: #110628;
}

.min {
  font-size: 9px !important;
  line-height: normal !important;
}

.price-tag-wrapper .features-section {
  align-self: start;
  height: -webkit-fill-available;
  font-family: 'SF Pro Display';
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  line-height: 10px;
  letter-spacing: 0.38px;
  color: #110628;
}

.hide {
  overflow: hidden;
}

.price-tag-wrapper .features-section .features-items-wrapper {
  display: grid;
  grid-template-columns: 12px 1fr;
  grid-column-gap: 5px;
  margin-bottom: 5px;
  font-size: 1em;
}

.many-items {
  margin-bottom: 2px !important;
}

.price-tag-wrapper .features-section .features-items-wrapper .deny {
  color: #A09BAE;
}

.price-tag-wrapper .buttons-section {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.price-tag-wrapper .buttons-section .btn-price-without-subscription {
  font-family: 'SF Pro Display';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #110628;
  background-color: #F4F3F7;
  border-radius: 12px;
  border-color: #110628;
  width: 175px;
  height: 36px;
}

.price-tag-wrapper .buttons-section>span {
  font-family: 'SF Pro Display';
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
  letter-spacing: 0.38px;
}

.price-tag-wrapper .buttons-section .btn-price-with-subscription {
  font-family: 'SF Pro Display';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: #110628;
  background-color: #FFD84E;
  border-radius: 12px;
  width: 175px;
  height: 36px;
}
